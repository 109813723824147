import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { maintenanceGuard } from './shared/guards/maintenance/maintenance.guard';
import { MaintenanceComponent } from './shared/components/maintenance/maintenance.component';
import { ErrorComponent } from './shared/components/error/error.component';

export const routes: Routes = [
  { path: 'error', component: ErrorComponent, title: 'Something went wrong' },
  { path: 'maintenance', component: MaintenanceComponent, title: 'Maintenance' },
  {
    path: 'home', title: 'Password Reset', canActivate: [maintenanceGuard], component: HomeComponent 
  },
  {
    path: 'woolworths-password-reset',
    canActivate: [maintenanceGuard],
    loadChildren: () => import('./woolworths-password-reset/woolworths-password-reset.module').then((m) => m.WoolworthsPasswordResetModule)
  },
  {
    path: 'endeavour-drinks-password-reset',
    canActivate: [maintenanceGuard],
    loadChildren: () => import('./edg-password-reset/edg-password-reset.module').then((m) => m.EdgPasswordResetModule)
  },
  {
    path: 'new-user',
    canActivate: [maintenanceGuard],
    loadChildren: () => import('./new-user-registration/new-user-registration.module').then((m) => m.NewUserRegistrationModule)
  },
  { path: '', redirectTo: '/home', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    paramsInheritanceStrategy: 'always',
  })],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
