import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { map, tap } from 'rxjs/operators';
import { MaintenanceService } from '../../services/maintenance/maintenance.service';

export const maintenanceGuard: CanActivateFn = () => {
  const maintenanceService = inject(MaintenanceService);
  const router = inject(Router);

  return maintenanceService.checkMaintenanceMode().pipe(
    tap(({ isMaintenanceMode, message }) => {
      if (isMaintenanceMode) {
        router.navigate(['maintenance'], { queryParams: { message } });
      }
    }),
    map(({ isMaintenanceMode }) => !isMaintenanceMode)
  );
};
