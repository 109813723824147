<div class="container">
  <h1 class="primaryColor">Password Portal</h1>
  <p>Please select the group you work in to register or reset your password</p>
  <div class="button-container">
    <button>
      <img src="assets/images/logo.svg" alt="Woolworths Group" routerLink="/woolworths-password-reset" />
    </button>
    <button>
      <img src="assets/images/edg_logo.png" alt="EGL Group" routerLink="/endeavour-drinks-password-reset" />
    </button>
  </div>
</div>
