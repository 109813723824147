import { Component, OnInit, SecurityContext } from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { MaintenanceService } from '../../services/maintenance/maintenance.service';

@Component({
  selector: 'app-maintenance',
  standalone: true,
  imports: [],
  template: `
    <div class="maintenance-page">
      <h1 class="primaryColor">Woolworths Self Service Password Reset</h1>
      <div class="message-container">
        <strong [innerHTML]="message"></strong>
      </div>
    </div>
  `,
  styles: [
    `
      .maintenance-page {
        display: flex;
        flex-direction: column;
        text-align: center;
      }
      .message-container {
        padding: 20px;
        margin: 20px;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      }
      strong {
        font-size: 1.2em;
        color: #333333;
      }
    `,
  ],
})
export class MaintenanceComponent implements OnInit {
  message: SafeHtml | string | null = 'The application is currently under maintenance. Please try again later.';

  constructor(
    private router: Router,
    private sanitizer: DomSanitizer,
    private maintenanceService: MaintenanceService
  ) {}

  ngOnInit() {
    this.maintenanceService.checkMaintenanceMode()?.subscribe(
      (response: { isMaintenanceMode: boolean; message?: string }) => {
        if (response?.isMaintenanceMode === true) {
          this.message = this.sanitizer.sanitize(
            SecurityContext.HTML,
            response?.message || 'The application is currently under maintenance. Please try again later.'
          );
        } else {
          this.router.navigateByUrl('/');
        }
      },
      () => {
        this.router.navigateByUrl('/');
      }
    );
  }
}
