import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PasswordResetFlowService {
  private flowState = {
    payrollOrEmail: false,
    mfaOptions: false,
    passwordReset: false,
  };

  private navigating = false;

  setStepCompleted(step: 'payrollOrEmail' | 'mfaOptions' | 'passwordReset') {
    this.flowState[step] = true;
  }

  isStepCompleted(step: 'payrollOrEmail' | 'mfaOptions' | 'passwordReset'): boolean {
    return this.flowState[step];
  }

  resetFlow() {
    this.flowState = {
      payrollOrEmail: false,
      mfaOptions: false,
      passwordReset: false,
    };
  }

  setNavigating(value: boolean) {
    this.navigating = value;
  }

  isNavigating(): boolean {
    return this.navigating;
  }
}
