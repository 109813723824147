import { Component } from '@angular/core';
import { PasswordResetFlowService } from '../woolworths-password-reset/password-reset-flow.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent {
  constructor(private flowService: PasswordResetFlowService) {}

  resetFlow() {
    this.flowService.resetFlow();
  }
}
