import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-maintenance',
  standalone: true,
  imports: [],
  template: `
    <div class="maintenance-page">
      <h1 class="primaryColor">Woolworths Self Service Password Reset</h1>
      <p>{{ message }}</p>
    </div>
  `,
  styles: [`
    .maintenance-page {
      text-align: center;
      margin-top: 50px;
    }
  `]
})
export class MaintenanceComponent implements OnInit {
  message: string = 'The application is currently under maintenance. Please try again later.';

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      if (params['message']) {
        this.message = params['message'];
      }
    });
  }
}
