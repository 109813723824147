import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MaintenanceService {
  constructor(private http: HttpClient) {}

  private readonly baseUrl = `${environment.backendUrl}/api`;

  checkMaintenanceMode(): Observable<{ isMaintenanceMode: boolean, message?: string }> {
    return this.http.get(`${this.baseUrl}/health`).pipe(
      map(() => ({ isMaintenanceMode: false })),
      catchError((error) => {
        if (error.status === 503) {
          const message = error.error?.message || 'The application is currently under maintenance. Please try again later.';
          return of({ isMaintenanceMode: true, message });
        }
        return of({ isMaintenanceMode: false });
      })
    );
  }
}
